export const environment = {
  apiGatewayConfig: {
    apiGatewayUrl: 'https://api-gateway.drcedirect.com/',
    apiGatewayCloudUrl: 'https://api-gateway-cloud.drcedirect.com',
    client: 'all',
    deployment: 'production',
    version: 'v0',
    enableCache: true,
    dynatraceConfig: {
      scriptUrl: 'https://js-cdn.dynatrace.com/jstag/17b628055ae/bf63792rhe/ab19e561e88b419c_complete.js'
    }
  },
  production: true,
  okta: {
    clientConfig: {
      issuer: 'https://auth.drcedirect.com/oauth2/default',
      clientId: 'eca-portal',
      redirectUri: 'https://www.drcedirect.com',
      pkce: true,
      tokenManager: {
        secure: true,
        storage: 'sessionStorage'
      }
    },
    samlRedirectUrl: 'https://auth.drcedirect.com/app/datarecognitioncorp_ecainsightportal_1/exknyrjdcH7FdfWGa4x6/sso/saml'
  },
  userManagementUrl: 'https://prod.uum.drcedirect.com',
  serviceNowUrl: 'https://datarecognitioncorp.service-now.com/customerserviceportal2?id=csp2_connect_chat',
  genesys: {
    deploymentId: '37a742bf-b5a0-47af-b552-d211ab3d8a44',
    environment: 'usw2',
    orgGuid: '4e8196cb-bd36-4309-9008-eea81adb60b3',
    script: 'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js'
  },
  confluenceUrl: 'https://api-gateway-cloud.drcedirect.com/confluence/wiki/rest/api'
};
